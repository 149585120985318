<template>
    <modal ref="refModalOrderCategories" v-loading="loading" titulo="Ordenar categorías" icon="categoria" no-aceptar adicional="Ordenar" @adicional="action">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 my-3 justify-center">
                <div class="col mr-5 ml-5">
                    <ValidationProvider v-slot="{errors}" rules="required" name="Seleccionar" class="d-flex flex-column">
                        <div v-for="(option, index) in options" :key="index" class="d-middle mt-2">
                            <input v-model="optionSelected" :value="option.value" type="radio" class="option-input radio black" />
                            <p class="text-general f-15">{{ option.name }}</p>
                        </div>
                        <span v-if="errors.length > 0" class="text-danger w-100 f-11 mt-2 ml-4">Debe seleccionar una opción</span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from "~/services/configurar/productos";

export default {
    data(){
        return {
            loading: false,
            optionSelected: '',
            options: [
                { id: 1, name: 'Alfabéticamente ascendente', value: 'AA' },
                { id: 2, name: 'Alfabéticamente descendente', value: 'AD' },
                { id: 3, name: 'Cantidad de productos descendente', value: 'CPD' },
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.refModalOrderCategories.toggle();
        },

        async action(){
            try {
                this.loading = true;
                const valid = await this.$refs.validacion.validate();
                if(!valid) return;

                const payload = { 
                    tipo: this.optionSelected
                };

                const { data } = await Service.orderCategories(payload);
                if (data.exito){
                    this.$emit('update');
                    this.notificacion('Mensaje', data.mensaje, 'success');
                    this.$refs.refModalOrderCategories.toggle();
                }
            } catch(e){
                this.error_catch(e);
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>
